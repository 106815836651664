import React, { useState, useEffect, useRef } from 'react'
import '../../assets/shared/modalVideo.css'
import close from '../../images/close-white.svg'
import meta from '../../store/meta.js'
import { observer } from 'mobx-react-lite'
export const ModalVideo = observer(() => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!meta.currentVideo?.url) return
    setLoading(true)

    const iframe = iframeRef.current;

    const handleLoad = () => {
      setLoading(false)
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad)
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad)
      }
    };
  }, [meta.currentVideo?.url])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        meta.closeVideo()
      }
    };

    if (meta.isModalVideoOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [meta.isModalVideoOpen])

  return (
    <div className={meta.isModalVideoOpen ? 'modalVideo active' : 'modalVideo'} onClick={() => meta.closeVideo()}>
      <div className={meta.isModalVideoOpen ? 'content active' : 'content'} onClick={e => e.stopPropagation()}>
        <div className='container'>
          <div className='row'>
            <div className="headCont">
              <p>{ meta.currentVideo?.full }</p>
              <img src={close} onClick={() => meta.closeVideo()} alt='close'/>
            </div>
            <div className={`'col-12' ${ meta.currentVideo?.name == 'HIMIA Showreel 2022' ? '' : 'col-md-8'}`} >
              <iframe
                ref={ iframeRef }
                src={ meta.currentVideo?.url }
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              >
              </iframe>
            </div>
            {
              meta.currentVideo?.name !== 'HIMIA Showreel 2022' && <div className="col-12 col-md-4 footCont">
                <div className="footBody">
                  <div>
                    <span>Client:</span>
                    <p>{ meta.currentVideo?.client }</p>
                  </div>
                  <div>
                    <span>Director:</span>
                    <p>{ meta.currentVideo?.director }</p>
                  </div>
                  <div>
                    <span>Services:</span>
                    <p>{ meta.currentVideo?.services }</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
})
