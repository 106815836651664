import React, { useEffect } from 'react';
import { motion } from 'framer-motion'
import '../assets/about.css'
import { ShowReel } from '../components/about/ShowReel'

const About = () => {
  useEffect(() => {
      document.title = 'HIMIA | About'
      window.scrollTo({ top: 0, behavior: 'instant'})
  }, []);
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{ duration: 0.2 }}
    >
      <div className='about'>
        <div className='container'>
          <div className='title'>
            <h1>ABOUT</h1>
            <h2>HIMIA IS A VFX/CREATIVE TEAM.</h2>
            <p>It's been 10 years since we began our journey and now we find ourselves here. Our goal is to bring art to life and execute your ideas with passion and taste. We are HIMIA, a visionary team with experience working with notable celebrities and brands. Our job is to provide post-production services and solutions to breathe life into your products. Our professionalism, hard work, and dedication are our main tools and the key to excellent results.</p>
            <h3>SERVICES</h3>
            <span>post-production/VFX, 3D animation, CGI</span>
          </div>
          <div className='show-reel'>
            <ShowReel />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About
