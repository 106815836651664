import React from 'react'
import { useRef, useEffect } from 'react'
import '../../assets/about/showReel.css'
import main from '../../videos/ree_preview_ORIGINAL.mp4'
import play from '../../images/shared/showreel/play.svg'
import meta from '../../store/meta'
import { observer } from 'mobx-react-lite'
import { isDesktop } from 'react-device-detect'

export const ShowReel = observer(() => {
  const buttonRef = useRef()

  const reel = {
    url: 'https://player.vimeo.com/video/811839110'
  }

  const handleMouseEnter = () => {
    if (!isDesktop) return

    buttonRef.current.style.opacity = '1'
  }

  const handleMouseLeave = () => {
    if (!isDesktop) return

    buttonRef.current.style.opacity = '0'
  }

  useEffect(() => {
    isDesktop ? buttonRef.current.style.opacity = '0' : buttonRef.current.style.opacity = '1'
  }, [])

  return (
    <div className='showReelAbout'>
      <div
        className='mainCont'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <video
          className='womenImg'
          src={main}
          autoPlay
          loop
          muted
          playsInline
        />
        <button onClick={ () => meta.showReel(reel) } ref={buttonRef}>
          <img src={play} alt='play'/>
          <span>WATCH SHOWREEL</span>
        </button>
      </div>
    </div>
  )
})
