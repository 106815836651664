import React from 'react'
import '../../assets/shared/alert.css'

export const Alert = ({ show, message }) => {
  const animated = show ? {
    opacity: 1,
    transform: `translateY(5px)`
  } : {
    opacity: 0,
    transform: 'translateY(-100px)'
  }

  return (
    <div className='alert' style={ animated }>
      { message }
    </div>
  )
}
