import React, {useState} from 'react'
import '../../assets/shared/recentWorks.css'
import img1 from '../../images/portfolio/MOSES_preview.jpg'
import img2 from '../../images/portfolio/FORTNITE_preview.jpg'
import img3 from '../../images/portfolio/Reebok x MSCHF_preview.jpg'
import img11 from '../../images/portfolio/PUMA_preview.png'
import img5 from '../../images/portfolio/COACHELLApreview.png'
import img14 from '../../images/portfolio/asics_x_bentgablenits_preview.jpg'

import video1 from '../../videos/moses_preview.mp4'
import video2 from '../../videos/fortniteXweeknd_preview.mp4'
import video3 from '../../videos/reebok_mschf_preview.mp4'
import video11 from '../../videos/pumaXmelo_preview.mp4'
import video5 from '../../videos/coachellaXyoutube_preview.mp4'
import video14 from '../../videos/asics_preview.mp4'
import meta from "../../store/meta"
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import play from '../../images/shared/showreel/play.svg'
import { isDesktop } from 'react-device-detect'

export const RecentWorks = observer(() => {
  const works = [
    {
      name: 'Asics x Bentgablenits - A Prize In Every Box',
      full: 'Asics x Bentgablenits - A Prize In Every Box',
      img: img14,
      video: video14,
      url: 'https://player.vimeo.com/video/984466984',
      client: 'Asics, Bentgablenits',
      director: ' Connor Brashier & Angelo Nitsopoulos',
      services: ' VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Moses Sumney - Vintage',
      full: 'Moses Sumney - Vintage',
      img: img1,
      video: video1,
      url: 'https://www.youtube.com/embed/CQdFOdyTGgc',
      client: 'Moses Sumney - Vintage',
      director: 'Moses Sumney',
      services: 'VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'COACHELLA x YouTube',
      full: 'COACHELLA On YouTube',
      img: img5,
      video: video5,
      url: 'https://player.vimeo.com/video/938556780',
      client: 'Coachella, YouTube',
      director: 'BRTHR',
      services: 'VFX, CGI, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Fortnite x WEEKND',
      full: 'The Weeknd x Fortnite Festival Trailer',
      img: img2,
      video: video2,
      url: 'https://www.youtube.com/embed/jIDSHW5tIrQ?si=fq4-VVwboc-TK5yz',
      client: 'Epic Games',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'PUMA x MELO',
      full: 'PUMA x MELO MB.03',
      img: img11,
      video: video11,
      url: 'https://player.vimeo.com/video/938556195',
      client: 'PUMA',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Reebok x MSCHF',
      full: 'Reebok x MSCHF Pump Omni Zone LX',
      img: img3,
      video: video3,
      url: 'https://player.vimeo.com/video/867451990',
      client: 'Reebok',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    }
  ]
  const [hoveredId, setHoveredId] = useState(null)
  const navigate = useNavigate()

  const handleMouseEnter = (id) => {
    setHoveredId(id)
  };

  const handleMouseLeave = () => {
    setHoveredId(null)
  };

  return (
    <>
      <div className='recent-works container'>
        <div className='title-recent col'>
          <h2>RECENT</h2>
          <button onClick={ () => navigate('/portfolio') } className='see-all d-none d-md-block'>SEE ALL</button>
        </div>

        <div className='works-cont row'>
          {works.map(function(work, i){
            return <div key={i} className='col-12 col-md-6 work-parent'>
              <div className='work-cont'>
                <div
                  className='image-cont'
                  onClick={ () => meta.showVideo(work) }
                  onMouseEnter={() => handleMouseEnter(work.name)}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={() => handleMouseEnter(work.name)}
                  onTouchEnd={handleMouseLeave}
                >
                  <img 
                    className='work-img'                      
                    src={work.img}
                    alt={work.url}
                  />
                  {isDesktop && hoveredId === work.name ? (
                    <video
                      className='work-img'
                      src={work.video}
                      autoPlay
                      loop
                      muted
                    />                     
                  ) : (
                    null
                  )}
                    <div
                      className='mask-cont'
                      style={{
                        opacity: isDesktop && hoveredId === work.name ? 1 : 0,
                      }}
                    >
                    <div className='corner1'></div>
                    <div className='corner2'></div>
                    <div className='title'>
                      <h3>VIDEO { i + 1 }:</h3>
                      <p>{ work.name }</p>
                    </div>
                    <div className='play'>
                      <img src={play} alt='play'/>
                      <span>CLICK TO PLAY</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
        <button onClick={ () => navigate('/portfolio') } className='see-all d-block d-md-none'>SEE ALL</button>
      </div>
    </>
  )
})
