import React, { useState } from 'react'
import '../assets/footer.css'
import copyright from '../images/footer/copyright.svg'
import titleDesk from '../images/footer/title-desk.png'
import titleMob from '../images/footer/title-mob.png'
import toTop from '../images/footer/to-top.svg'
import vimeo from '../images/vimeo-whtite.svg'
import instagram from '../images/instagram-white.svg'
import validationSchema from '../services/validation/schemas/contact.json'
import { useValidForm } from '../services/validation/validForm'
import meta from '../store/meta.js'

const Footer = () => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [isSendingFailed, setIsSendingFailed] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const submit = async (e) => {
    e.preventDefault()

    setIsSendingFailed(false)
    if (isLoading) return

    setValidationErrors(validForm.validateFields({ name, email, description }))
    if (validForm.isValid()) {
      setIsLoading(true)
      const response = await fetch('/server/sendMail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          description: description
        }),
      });
      try {
        const result = await response.json()
        if (result?.status === 'success') {
          setEmail('')
          setName('')
          setDescription('')
          meta.showAlertWindow('Your message has been successfully sent! 🎉')
        } else {
          setIsSendingFailed(true)
        }
      } catch (e) {
        setIsSendingFailed(true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className='footer' >
      <div className='container'>
        <div className="row">
          <div className="col-12 col-md-3 first-cont">
            <img className="d-block d-md-none" src={titleMob} alt='titleMob'/>
            <img className="d-none d-md-block" src={titleDesk} alt='titleMob'/>
          </div>
          <div className="col-12  col-md-4">
            <form>
              <input
                className={validationErrors.name.length ? 'red-border' : ''}
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className='validation-error'>{validationErrors?.name[0]}</p>
              <input
                className={validationErrors.email.length ? 'red-border' : ''}
                type="text"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className='validation-error' >{validationErrors?.email[0]}</p>
              <input
                className={validationErrors.description.length ? 'red-border' : ''}
                type='text'
                placeholder='Your message'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <p className='validation-error'>{validationErrors?.description[0]}</p>
              <div className='submit-cont'>
                <button
                  onClick={(e) => submit(e)}
                  className={ isLoading ? 'send-button button--loading' : 'send-button' }>
                  <span className="button__text">SEND</span>
                </button>
                { isSendingFailed && <span className='submitting-error'>Something went wrong. Please try again.</span> }
                <div className='networks d-block d-md-none'>
                  <img src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')}/>
                  <img src={instagram} alt='instagram' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')}/>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12  col-md-5 third-cont">
            {/* <p>OR SEND A MESSAGE ON</p> */}
            <span>HELLO@HIMIA.DIGITAL</span>
            <div className='networks d-none d-md-block'>
              <img src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')}/>
              <img src={instagram} alt='vimeo' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')}/>
          </div>
          </div>
          <div className="col-12">
            <div className="fourth-cont">
              <div className='copyright'>
                <img src={copyright} alt='vimeo'/>
                <span>COPYRIGHT 2024 HIMIA.</span>
              </div>
              <div className='to-top' onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) }>
                <span>TO TOP</span>
                <img src={toTop} alt='vimeo'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
