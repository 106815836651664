import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from './Menu'
import '../assets/header.css'
import menu from '../images/menu.svg'
import { useNavigate } from 'react-router-dom'
import vimeo from '../images/vimeo-whtite.svg'
import instagram from '../images/instagram-white.svg'
import H from '../images/H-rotated.gif'

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate();

  return (
    <>
      <Menu active={ isMenuOpen } setActive={ (state) => setMenuOpen(state) } />
      <div className='header'>
        <div className='container'>
          <div className='header-cont'>
            <div className='left-cont'>
              <img src={H} alt='HIMIA'/>
              <Link to="/" className="text-center">IMIA</Link>
            </div>
            <div className='mid-cont d-none d-sm-block' >
              <img src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')}/>
              <img src={instagram} alt='vimeo' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')}/>
            </div>
            <div className='right-cont'>
              <button onClick={() => window.scrollTo(0, document.body.scrollHeight) } className='d-none d-sm-inline-block'>CONTACT</button>
              <img src={menu} alt='menu' onClick={ () => setMenuOpen(true)}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
