import { observable, action, makeObservable } from 'mobx'

class Meta {
  constructor() {
    this.currentVideo = null
    this.isModalVideoOpen = false
    this.currentReel = null
    this.isModalReelOpen = false
    this.showAlert = false
    this.alertMessage = ''

    makeObservable(this, {
      currentVideo: observable,
      isModalVideoOpen: observable,
      currentReel: observable,
      isModalReelOpen: observable,
      showAlert: observable,
      alertMessage: observable,
      showVideo: action,
      closeVideo: action,
      showReel: action,
      closeReel: action,
      showAlertWindow: action
    })
  }

  showVideo(state) {
    this.currentVideo = state
    this.isModalVideoOpen = true
    document.body.style.overflow = 'hidden'
  }

  closeVideo() {
    this.isModalVideoOpen = false
    document.body.style.overflow = ''
    setTimeout(() => {
      if (this.isModalVideoOpen === false) {
        this.currentVideo = null
      }
    }, 300)
  }

  showReel(state) {
    this.currentReel = state
    this.isModalReelOpen = true
    document.body.style.overflow = 'hidden'
  }

  closeReel() {
    this.isModalReelOpen = false
    document.body.style.overflow = ''
    setTimeout(() => {
      if (this.isModalReelOpen === false) {
        this.currentReel = null
      }
    }, 300)
  }

  showAlertWindow(text) {
    this.showAlert = true
    this.alertMessage = text

    setTimeout(() => {
      this.showAlert = false
      this.alertMessage = text
    }, 2000)
  }
}

export default new Meta()
