import React, { useState, useEffect, useRef } from 'react'
import '../../assets/shared/modalReel.css'
import close from '../../images/close-white.svg'
import meta from '../../store/meta.js'
import { observer } from 'mobx-react-lite'
export const ModalReel = observer(() => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!meta.currentReel?.url) return
    setLoading(true)

    const iframe = iframeRef.current;

    const handleLoad = () => {
      setLoading(false)
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad)
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad)
      }
    };
  }, [meta.currentReel?.url])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        meta.closeReel()
      }
    };

    if (meta.isModalReelOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [meta.isModalReelOpen])

  return (
    <div className={meta.isModalReelOpen ? 'modalReel active' : 'modalReel'} onClick={() => meta.closeReel()}>
      <div className={meta.isModalReelOpen ? 'content active' : 'content'} onClick={e => e.stopPropagation()}>
        <div className='container'>
          <div className='headCont'>
            <img src={close} onClick={() => meta.closeReel()} alt='close'/>
          </div>
          <iframe
            ref={ iframeRef }
            src={ meta.currentReel?.url }
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          >
          </iframe>
        </div>
      </div>
    </div>
  )
})
